<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="requirementsList === undefined"
    >
      <h4 class="alert-heading">
        Error fetching specification data
      </h4>
    </b-alert>

    <b-row
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <h3 class="text-primary">
                    Kompozition_
                  </h3>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title" style="width: 12rem">
                    Model:
                  </p>
                  <p class="invoice-date">
                    {{ specification.model }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title" style="width: 12rem">
                    Specification:
                  </p>
                  <p class="invoice-date">
                    {{ specification.title }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title" style="width: 12rem">
                    No. of Requirements:
                  </p>
                  <p class="invoice-date">
                    {{ requirementsList.length }}
                  </p>
                </div>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h2 class="invoice-title font-large-1">
                  {{ specification.title }}
                </h2>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Time exported:
                  </p>
                  <p class="invoice-date">
                    {{ Date() | formatDate }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Exported by:
                  </p>
                  <p class="invoice-date">
                    {{ $store.state.auth.email }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Requirements Table -->
          <b-table-lite
            responsive
            :items="requirementsList"
            :fields="['id', 'requirement']"
          >
            <template #cell(priority)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ resolvePriority(data.item) }}
              </b-card-text>
            </template>

            <template #cell(id)="data">
              <b-card-text class="font-weight-bold mb-25 text-nowrap">
                <small>[{{ data.item.display_id }}]</small>
              </b-card-text>
            </template>

            <template #cell(requirement)="data">
              <b-card-text
                class="mb-25 text-italic"
                :class="resolveObjectTextStyling(data.item)"
              >
                <header v-if="data.item.priority === 'Heading'" class="rpt">
                  <h1 v-if="data.item.priority === 'Heading' && ['1',1].includes(data.item.level)" :id="data.item.id">
                    {{ data.item.section }} {{ stripHTML(data.item.object_text) }}
                  </h1>
                  <h2 v-else-if="data.item.priority === 'Heading' && ['2',2].includes(data.item.level)" :id="data.item.id">
                    {{ data.item.section }} {{ stripHTML(data.item.object_text) }}
                  </h2>
                  <h3 v-else-if="data.item.priority === 'Heading' && ['3',3].includes(data.item.level)" :id="data.item.id">
                    {{ data.item.section }} {{ stripHTML(data.item.object_text) }}
                  </h3>
                  <h4 v-else-if="data.item.priority === 'Heading' && ['4',4].includes(data.item.level)" :id="data.item.id">
                    {{ data.item.section }} {{ stripHTML(data.item.object_text) }}
                  </h4>
                  <h5 v-else-if="data.item.priority === 'Heading'" :id="data.item.id">
                    {{ data.item.section }} {{ stripHTML(data.item.object_text) }}
                  </h5>
                </header>
                <div v-else>
                  <span v-sanitized-html="data.item.object_text" />
                  <span v-if="data.item.priority !== 'Heading' && data.item.priority !== 'Advice'">&nbsp;[{{ data.item.priority }}]</span>
                </div>
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span>
              This information was exported from the Kompozition platform. Please refer back to the Kompozition
              platform to view the most up-to-date data.
            </span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Download -->
          <!--<b-button-->
          <!--  v-ripple.400="'rgba(186, 191, 199, 0.15)'"-->
          <!--  variant="outline-secondary"-->
          <!--  class="mb-75"-->
          <!--  block-->
          <!--&gt;-->
          <!--  Download-->
          <!--</b-button>-->

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="info"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { computed, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import exportHelpers from './exportHelpers'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
  },
  setup() {
    const { resolvePriority, resolveObjectTextStyling } = exportHelpers()

    // Route Params
    const { route } = useRouter()
    const routeParams = computed(() => route.value.params)

    const specification = computed(() => store.state.requirements.specificationRequirements.specification)
    const requirementsList = computed(() => store.state.requirements.specificationRequirements.requirements)
    const fetchRequirements = () => {
      store
        .dispatch('requirements/getSpecificationRequirements', routeParams.value.id)
    }
    fetchRequirements()

    onUnmounted(() => {
      specification.value = null
      requirementsList.value = null
    })

    const printInvoice = () => {
      window.print()
    }

    return {
      resolvePriority,
      resolveObjectTextStyling,

      specification,
      requirementsList,
      fetchRequirements,

      printInvoice,
    }
  },
  methods: {
    stripHTML(htmlString) {
      return htmlString.replace(/<[^>]+>/g, '')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .row.content-header {
    display: none;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
