export default function exportHelpers() {
  const resolvePriority = requirement => {
    if (requirement.priority === 'Heading') return null
    if (requirement.priority === 'Advice') return null
    return requirement.priority
  }

  const resolveObjectTextStyling = requirement => {
    if (requirement.priority === 'Heading') {
      if (requirement.level === '1') return 'font-large-1 font-weight-bold mt-25'
      if (requirement.level === '2') return 'font-medium-4 font-weight-bold'
      if (requirement.level === '2') return 'font-medium-2 font-weight-bold'
      return 'font-weight-bold'
    }
    if (requirement.priority === 'Advice') return 'text-secondary font-italic'
    return ''
  }

  return {
    resolvePriority,
    resolveObjectTextStyling,
  }
}
